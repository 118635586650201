import {LinkButton} from '~/components/LinkButton';
import {TitleSection} from './TitleSection';

export interface SectionQuoteBaseProps {
  img?: {
    url: string;
    altText: string;
  };
  title: React.ReactNode;
  callToActionText?: React.ReactNode;
  className?: string;
  linkTo?: string;
}

export function SectionQuoteBase({
  img,
  title,
  callToActionText,
  className,
  linkTo = '?cotiza-tu-proyecto=true',
}: SectionQuoteBaseProps) {
  return (
    <section className={`flex flex-col items-center ${className ?? ''}`}>
      <TitleSection className="mb-6" i18nKey="cotiza" />

      <h1 className="px-4 mb-12 text-3xl font-light text-center uppercase sm:text-6xl text-blue-dark">
        {title}
      </h1>

      {callToActionText ? (
        <LinkButton
          to={linkTo}
          className="mx-4 mb-12 sm:mx-0 sm:text-xl text-md"
        >
          {callToActionText}
        </LinkButton>
      ) : null}

      <div className="overflow-hidden w-full bg-black rounded-t-2xl h-[300px] md:h-[600px]">
        <img
          alt={img?.altText}
          src={img?.url}
          className="object-cover w-full h-full"
          loading="eager"
          width="1040"
          height="600"
        />
      </div>
    </section>
  );
}
